import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import BlogPost from 'common/components/BlogPost';
import Container from 'common/components/UI/Container';
import SectionWrapper, {
  SectionHeader,
  FeatureWrapper,
} from './workSection.style';

const WorkSection = () => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        workData {
          slogan
          features {
            id
            icon {
              publicURL
            }
            title
            description
          }
        }
      }
    }
  `);

  const { features } = data.charityJson.workData;
  return (
    <SectionWrapper id="work">
      <Container width="1260px">
        <SectionHeader>
          <Heading content="Our Concerns" />
        </SectionHeader>
        <FeatureWrapper>
          <BlogPost
            thumbUrl={features[0].icon.publicURL}
            title="Safety"
            excerpt="We are concerned about the safety of children, animals, residents and walkers"
          />
          <BlogPost
            thumbUrl={features[1].icon.publicURL}
            title="Environment"
            excerpt="We are concerned about the impact of pathways, noise and lights on The Lammas Lands, a Site of Nature Conservation Importance"
          />
          <BlogPost
            thumbUrl={features[2].icon.publicURL}
            title="Wildlife"
            excerpt="We are concerned about the impact on endagered species such as dormice, bats, rare breeding birds, mammals and invertebrates"
          />
        </FeatureWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default WorkSection;
