import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/components/Box';
import Image from 'common/components/Image';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import BlockWrapper, {
  ContentWrapper,
  List,
  Item,
  ImageWrapper,
} from './promotionBlock.style';

const PromotionBlock = ({ row, col }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        promotionData {
          image {
            publicURL
          }
          slogan
          title
          text1
          text2
        }
      }
    }
  `);

  const { image } =
    data.charityJson.promotionData;

  return (
    <BlockWrapper id="ourCommunity">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading as="h5" content="GET OUR COMMUNITY ON BOARD" />
              <Heading content="You can make the difference, right here and right now!" />
              <p>
                <strong>Your action and your say matters</strong> more than ever right now! Below are a couple of examples of what you can
                do to <strong>make your voice heard.</strong>
              </p>
              <List>
                <Item><strong>Talk</strong> to your friends and neighbours</Item>
                <Item><strong>Write</strong> to your MP & Counsellors</Item>
                <Item><strong>Visit</strong> the sites and imagine the effect of the proposals</Item>
              </List>
            </ContentWrapper>
          </Box>
          <Box className="col" {...col}>
            <ImageWrapper>
              <Image src={image.publicURL} alt="Charity Landing" />
            </ImageWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// PromotionBlock style props
PromotionBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// PromotionBlock default style
PromotionBlock.defaultProps = {
  // PromotionBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // PromotionBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default PromotionBlock;
