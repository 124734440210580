import React from 'react';
import { Icon } from 'react-icons-kit';
import { androidArrowForward } from 'react-icons-kit/ionicons/androidArrowForward';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import CallToActionArea, { Left, Right } from './callToAction.style';

const CallToAction = () => {
  return (
    <CallToActionArea>
      <Container className="Container">
        <Left>
          <Heading as="h3" content="10 Year Lammas Lands Management Plan (2017)" />
          <p>
            The report lays out a <strong>range of prescriptions</strong> with the aim of maintaining and improving the site for <strong>people</strong> and <strong>wildlife</strong>. Which we believe are in <strong>direct conflict with the current proposals!</strong>
          </p>
        </Left>
        <Right>
          <a href="/management-plan-2017.pdf" target="_blank">Download <Icon icon={androidArrowForward} /></a>
        </Right>
      </Container>
    </CallToActionArea>
  );
};

export default CallToAction;
