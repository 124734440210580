import React from 'react';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import BlockWrapper, {
  CounterWrapper,
  CounterItem,
} from 'containers/shared/counterblock/milestoneBlock.style';

const MilestoneBlock = () => {
  return (
    <Container id="milestone" width="1260px">
      <BlockWrapper>
        <h2>Nature in numbers</h2>
        <CounterWrapper>
          <CounterItem>
            <Heading as="h3" content="227" />
            <Text content="Invertebrates" />
          </CounterItem>
          <CounterItem>
            <Heading as="h3" content="18" />
            <Text content="At Risk Bird Species" />
          </CounterItem>
          <CounterItem>
            <Heading as="h3" content="108" />
            <Text content="Flowering Plant Species" />
          </CounterItem>
          <CounterItem>
            <Heading as="h3" content="4" />
            <Text content="Meadows" />
          </CounterItem>
        </CounterWrapper>
      </BlockWrapper>
    </Container>
  );
};

export default MilestoneBlock;
