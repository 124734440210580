import React from 'react';
import { Link } from 'gatsby';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import BlockWrapper, {
  CounterWrapper,
  CounterItem,
} from 'containers/shared/counterblock/milestoneBlock.style';

const MilestoneBlock = ({ showLink = true }) => {
  return (
    <Container id="milestone" width="1260px">
      <BlockWrapper>
        <h2>Why people visit</h2>
        <CounterWrapper>
          <CounterItem>
            <Heading as="h3" content="54.5%" />
            <Text content="Rural feel" />
          </CounterItem>
          <CounterItem>
            <Heading as="h3" content="53.1%" />
            <Text content="Scenery" />
          </CounterItem>
          <CounterItem>
            <Heading as="h3" content="38.8%" />
            <Text content="Feels safe" />
          </CounterItem>
          <CounterItem>
            <Heading as="h3" content="30.4%" />
            <Text content="Close to local facilities" />
          </CounterItem>
        </CounterWrapper>
        {
          showLink === true &&
          <div className="learn__more-btn-container">
            <Link className="learn__more-btn" to="/visitor-demographics">
              <span className="hyphen" />
              <span className="btn_text">Learn more about visitor demographics</span>
            </Link>
          </div>
        }
      </BlockWrapper>
    </Container>
  );
};

export default MilestoneBlock;
