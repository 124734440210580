import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from './humanityBlock.style';

const HumanityBlock = ({ row, col }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        humanityData {
          image {
            childImageSharp {
              gatsbyImageData(
                width: 633
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);

  const { image } = data.charityJson.humanityData;

  return (
    <BlockWrapper id="socialFundraising">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ImageWrapper>
              <GatsbyImage
                src={
                  (image !== null) | undefined
                    ? image.childImageSharp.gatsbyImageData
                    : {}
                }
                alt="Charity Landing"
              />
            </ImageWrapper>
          </Box>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading content="We need your help to protect this special place" />
              <p>
                The council(s) responsible for the Lammas Lands have proposed to build a <strong>4m wide compound or
                raised cycle track</strong> through the functional floodplain meadows and across the river as part
                of the Godalming Greenway project. The proposed development would flow
                traffic directly from the A3100 down into this <strong>designated site of nature
                conservation importance</strong>, and would potentially be an <strong>ecological disaster</strong> for this protected area.
              </p>
              <p>
                There would be a necessity for these paths to be both lit and protected from both noise and
                refuse. We believe this is not possible while maintaining the <strong>delicate natural habitat</strong> that forms
                a key part of <strong>what makes Godalming special</strong>.
              </p>

              <Link className="learn__more-btn" to="/godalming-greenway">
                <span className="hyphen" />
                <span className="btn_text">Learn More about the issues with the Greenway Plans</span>
              </Link>
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // HumanityBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default HumanityBlock;
