import React from 'react';
import { Link } from 'gatsby';
import LeftBar from './leftBar';
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea,
} from 'containers/shared/banner/bannerSection.style';

import BannerImage from './banner-image.jpg';

const BannerSection = () => {
  return (
    <BannerWrapper>
      <LeftBar text="SCROLL DOWN" offset={81} sectionId="#feature" />
      <ContentWrapper>
        <TextArea>
          <h2>
            Lammas Lands <small>Godalming</small>
          </h2>
          <p>
            <strong>The Lammas Lands</strong> are floodplain meadows covering 31.8 hectares located in the town
            of Godalming in Surrey.
          </p>
          <p>
            The Lammas Lands are recognised as a site of <strong>Nature Conservation Importance</strong>, an Area
            of <strong>High Archaeological Potential</strong> and an Area of <strong>Strategic Visual Importance</strong>.
          </p>
          <p>
            A total of <strong>108 species of flowering plants</strong>, <strong>227 invertebrates</strong> (including a number of rare and scarce species)
            and <strong>six amber and twelve red listed species</strong> of bird call this place home.
          </p>
          <Link className="learn__more-btn" to="/wildlife">
            <span className="hyphen" />
            <span className="btn_text">Explore the Wildlife</span>
          </Link>
        </TextArea>
        <ImageArea style={{ backgroundImage: `url(${BannerImage})` }} />
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;
